import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import BlueButton from "./BlueButton";
import { withPrefix } from "gatsby";
import Layout from '../components/layout';

const styles = theme => ({
  container: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing.unit * 22,
      paddingRight: theme.spacing.unit * 22,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5,
    }
  },
  row: {
    paddingLeft: theme.spacing.unit * 5,
    paddingRight: theme.spacing.unit * 5,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
  },
  divider: {
    [theme.breakpoints.up("sm")]: {
      borderRight: "solid 1px #d1d1d1"
    }
  },
  title: {
    ...theme.typography.subheading,
    display: "flex",
    flexGrow: 0,
    flexWrap: "wrap",
    justifyContent: "center",
    color: "black",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: "900",
    fontStretch: "normal",
    letterSpacing: "normal",
    textTransform: "uppercase",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    }
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  listItem: {
    display: "flex",
  },
  space: {
    marginTop: theme.spacing.unit * 2
  },
  rangeButton: {
    height: "52px",
    backgroundColor: "#f8f8f8",
    textAlign: "center",
    padding: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  pricing: {
    height: "69px",
    marginBottom: "45px",
  },
  quoteButton: {
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    zIndex: "100",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      position: "fixed",
      top: "95%",
    },

    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: "90%",
      left: "0",
      width: "100%",
      backgroundColor: "white",
      height: "10%",
      borderTop: "1px solid #d1d1d1",
      padding: "0 30px",
    }
  },
  spacing: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  bullet: {
    marginRight: "15px"
  }
});

const PricingTable = ({ plans, classes, location }) => (
  <Layout location={location}>
    <Grid container className={classes.container}>
      {plans &&
      plans.map((v, k) => (
        <Grid
          key={k}
          className={classNames(
            classes.row,
            classes.divider,
            classes.spacing
          )}
          item
          xs={12}
          sm={4}
        >
          <Grid
            container
            className={classes.pricing}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <img src={withPrefix(v.image)} />
          </Grid>
          <Typography className={classes.title}>
            {v.plan}
          </Typography>
          <div className={classes.space} />
          <div className={classes.rangeButton}>{v.price}</div>
          <Typography
            className={classNames(classes.paragraph, classes.space)}
          >
            {v.description}
          </Typography>
          {v.items.map((val, key) => (
            <Typography
              key={key}
              className={classNames(
                classes.paragraph,
                classes.listItem,
                classes.space
              )}
              gutterBottom
            >
              <span className={classes.bullet}>&#8226;</span>
              {val}
            </Typography>
          ))}
          {v.note && (
            <Typography
              className={classNames(classes.paragraph, classes.space)}
              gutterBottom
            >
              {v.note}
            </Typography>
          )}
          <Typography
            className={classNames(classes.paragraph, classes.space)}
          >
            {v.project}
          </Typography>
          {v.examples.split("|").map((val, key) => (
            <Typography
              key={key}
              className={classNames(
                classes.paragraph,
                classes.listItem,
                classes.space
              )}
              gutterBottom
            >
              <span className={classes.bullet}>&#8226;</span>
              {val}
            </Typography>
          ))}
        </Grid>
      ))}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.space}
      >
        <Grid className={classes.quoteButton} item xs={12} sm={4}>
          <BlueButton to="/letstalk" text="Get a quote" />
        </Grid>
      </Grid>
    </Grid>
  </Layout>
);

export default withStyles(styles)(PricingTable);
