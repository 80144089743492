import React from "react";

import TabBar from "../../components/TabBar";
import { servicesFields } from "../../metadata";
import PricingTable from "../../components/PricingTable";
import { graphql } from 'gatsby';

const ServicesPricingPagePreviewTemplate = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { plans } = frontmatter.pricing;

  return (
    <React.Fragment>
      <TabBar fields={servicesFields} />
      <PricingTable plans={plans} location={location} />
    </React.Fragment>
  );
};

export default ServicesPricingPagePreviewTemplate;

export const pageQuery = graphql`
  query ServicesPricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        description
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
            note
            image
            examples
            project
          }
        }
      }
    }
  }
`;
